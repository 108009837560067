// src/components/Banner.jsx
import React from "react";
import "./Banner.css";
import logo from "../assets/logo.png";

function Banner({ playerTag, onLogout }) {
  const handleLogout = () => {
    onLogout();
  };

  return (
    <div className="banner-container">
      <div className="banner-logo-container">
        <img src={logo} alt="Brawl360 Logo" className="banner-logo" />
      </div>
      <div className="banner-title-container">
        <h1 className="banner-title">BRAWL360</h1>
      </div>
      <div className="banner-user-container">
        <span className="logged-in-text">Logged in as: {playerTag}</span>
        <button className="logout-button" onClick={handleLogout}>
          LOGOUT
        </button>
      </div>
    </div>
  );
}

export default Banner;
