// src/components/RedirectIndex.js
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function RedirectIndex() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/index.html") {
      navigate("/", { replace: true });
    }
  }, [location, navigate]);

  return null;
}

export default RedirectIndex;
