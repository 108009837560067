// src/components/NavLinks.js
import React from "react";
import { useLocation, Link } from "react-router-dom";
import "../App.css"; // Ensure App.css is imported

function NavLinks() {
  const location = useLocation();
  const isMapMaster = location.pathname === "/map-master";
  const isRanked = location.pathname === "/ranked-assistant";

  return (
    <div className="navlinks-container">
      <Link
        to="/map-master"
        className={isMapMaster ? "navlinks-activeLink" : "navlinks-link"}
      >
        Map Master
      </Link>
      &nbsp; | &nbsp;
      <Link
        to="/ranked-assistant"
        className={isRanked ? "navlinks-activeLink" : "navlinks-link"}
      >
        {"Ranked Assistant"}
      </Link>
    </div>
  );
}

export default NavLinks;
