import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import NavLinks from "../components/NavLinks";
import "./RankedAssistant.css";
import "../App.css";

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const BRAWLER_IMAGES = {
  shelly: `${BACKEND_BASE_URL}/static/28000003.png`,
  colt: `${BACKEND_BASE_URL}/static/28000004.png`,
  bull: `${BACKEND_BASE_URL}/static/28000010.png`,
  brock: `${BACKEND_BASE_URL}/static/28000005.png`,
  rico: `${BACKEND_BASE_URL}/static/28000011.png`,
  spike: `${BACKEND_BASE_URL}/static/28000016.png`,
  barley: `${BACKEND_BASE_URL}/static/28000012.png`,
  jessie: `${BACKEND_BASE_URL}/static/28000006.png`,
  nita: `${BACKEND_BASE_URL}/static/28000007.png`,
  dynamike: `${BACKEND_BASE_URL}/static/28000008.png`,
  "el primo": `${BACKEND_BASE_URL}/static/28000009.png`,
  mortis: `${BACKEND_BASE_URL}/static/28000014.png`,
  crow: `${BACKEND_BASE_URL}/static/28000017.png`,
  poco: `${BACKEND_BASE_URL}/static/28000013.png`,
  bo: `${BACKEND_BASE_URL}/static/28000015.png`,
  piper: `${BACKEND_BASE_URL}/static/28000018.png`,
  pam: `${BACKEND_BASE_URL}/static/28000028.png`,
  tara: `${BACKEND_BASE_URL}/static/28000029.png`,
  darryl: `${BACKEND_BASE_URL}/static/28000034.png`,
  penny: `${BACKEND_BASE_URL}/static/28000035.png`,
  frank: `${BACKEND_BASE_URL}/static/28000036.png`,
  gene: `${BACKEND_BASE_URL}/static/28000038.png`,
  tick: `${BACKEND_BASE_URL}/static/28000042.png`,
  leon: `${BACKEND_BASE_URL}/static/28000037.png`,
  rosa: `${BACKEND_BASE_URL}/static/28000040.png`,
  carl: `${BACKEND_BASE_URL}/static/28000039.png`,
  bibi: `${BACKEND_BASE_URL}/static/28000041.png`,
  "8-bit": `${BACKEND_BASE_URL}/static/28000043.png`,
  sandy: `${BACKEND_BASE_URL}/static/28000044.png`,
  bea: `${BACKEND_BASE_URL}/static/28000046.png`,
  emz: `${BACKEND_BASE_URL}/static/28000045.png`,
  "mr. p": `${BACKEND_BASE_URL}/static/28000048.png`,
  max: `${BACKEND_BASE_URL}/static/28000047.png`,
  jacky: `${BACKEND_BASE_URL}/static/28000049.png`,
  gale: `${BACKEND_BASE_URL}/static/28000051.png`,
  nani: `${BACKEND_BASE_URL}/static/28000052.png`,
  sprout: `${BACKEND_BASE_URL}/static/28000050.png`,
  surge: `${BACKEND_BASE_URL}/static/28000053.png`,
  colette: `${BACKEND_BASE_URL}/static/28000054.png`,
  amber: `${BACKEND_BASE_URL}/static/28000055.png`,
  lou: `${BACKEND_BASE_URL}/static/28000056.png`,
  byron: `${BACKEND_BASE_URL}/static/28000057.png`,
  edgar: `${BACKEND_BASE_URL}/static/28000058.png`,
  ruffs: `${BACKEND_BASE_URL}/static/28000059.png`,
  stu: `${BACKEND_BASE_URL}/static/28000062.png`,
  belle: `${BACKEND_BASE_URL}/static/28000065.png`,
  squeak: `${BACKEND_BASE_URL}/static/28000066.png`,
  grom: `${BACKEND_BASE_URL}/static/28000080.png`,
  buzz: `${BACKEND_BASE_URL}/static/28000067.png`,
  griff: `${BACKEND_BASE_URL}/static/28000068.png`,
  ash: `${BACKEND_BASE_URL}/static/28000071.png`,
  meg: `${BACKEND_BASE_URL}/static/28000074.png`,
  lola: `${BACKEND_BASE_URL}/static/28000075.png`,
  fang: `${BACKEND_BASE_URL}/static/28000081.png`,
  eve: `${BACKEND_BASE_URL}/static/28000082.png`,
  janet: `${BACKEND_BASE_URL}/static/28000085.png`,
  bonnie: `${BACKEND_BASE_URL}/static/28000086.png`,
  otis: `${BACKEND_BASE_URL}/static/28000105.png`,
  sam: `${BACKEND_BASE_URL}/static/28000149.png`,
  gus: `${BACKEND_BASE_URL}/static/28000150.png`,
  buster: `${BACKEND_BASE_URL}/static/28000160.png`,
  chester: `${BACKEND_BASE_URL}/static/28000185.png`,
  gray: `${BACKEND_BASE_URL}/static/28000183.png`,
  mandy: `${BACKEND_BASE_URL}/static/28000184.png`,
  "r-t": `${BACKEND_BASE_URL}/static/28000245.png`,
  willow: `${BACKEND_BASE_URL}/static/28000246.png`,
  maisie: `${BACKEND_BASE_URL}/static/28000265.png`,
  hank: `${BACKEND_BASE_URL}/static/28000266.png`,
  cordelius: `${BACKEND_BASE_URL}/static/28000288.png`,
  doug: `${BACKEND_BASE_URL}/static/28000289.png`,
  pearl: `${BACKEND_BASE_URL}/static/28000319.png`,
  chuck: `${BACKEND_BASE_URL}/static/28000320.png`,
  charlie: `${BACKEND_BASE_URL}/static/28000333.png`,
  mico: `${BACKEND_BASE_URL}/static/28000381.png`,
  kit: `${BACKEND_BASE_URL}/static/28000382.png`,
  "larry & lawrie": `${BACKEND_BASE_URL}/static/28000387.png`,
  melodie: `${BACKEND_BASE_URL}/static/28000429.png`,
  angelo: `${BACKEND_BASE_URL}/static/28000430.png`,
  draco: `${BACKEND_BASE_URL}/static/28000465.png`,
  lily: `${BACKEND_BASE_URL}/static/28000464.png`,
  berry: `${BACKEND_BASE_URL}/static/28000532.png`,
  clancy: `${BACKEND_BASE_URL}/static/28000531.png`,
  moe: `${BACKEND_BASE_URL}/static/28000613.png`,
  kenji: `${BACKEND_BASE_URL}/static/28000593.png`,
};

function RankedAssistant({ playerTag, onLogout }) {
  // Add playerIndex state
  const [playerIndex, setPlayerIndex] = useState("1");

  // States
  const [phase, setPhase] = useState("select_map");
  const [maps, setMaps] = useState([]);
  const [mapSearch, setMapSearch] = useState("");
  const [selectedMap, setSelectedMap] = useState(null);
  const [teamColor, setTeamColor] = useState("");
  const [brawlerSearch, setBrawlerSearch] = useState("");
  const [myBans, setMyBans] = useState([]);
  const [enemyBans, setEnemyBans] = useState([]);
  const [allBrawlers, setAllBrawlers] = useState([]);
  const [enemyBrawlerSearch, setEnemyBrawlerSearch] = useState("");
  const [allyPicks, setAllyPicks] = useState([]);
  const [enemyPicks, setEnemyPicks] = useState([]);
  const [pickPhaseUserSearch, setPickPhaseUserSearch] = useState("");
  const [pickPhaseEnemySearch, setPickPhaseEnemySearch] = useState("");
  const [suggestedPicksUser, setSuggestedPicksUser] = useState([]);
  const [suggestedPicksEnemy, setSuggestedPicksEnemy] = useState([]);
  const [ownedBrawlers, setOwnedBrawlers] = useState([]);
  const [predictedWinPercentage, setPredictedWinPercentage] = useState(null);

  const [allSuggestedBans, setAllSuggestedBans] = useState([]);
  const [displayedBans, setDisplayedBans] = useState([]);
  const [allyNextIndex, setAllyNextIndex] = useState(0);
  const [allSuggestedBansEnemy, setAllSuggestedBansEnemy] = useState([]);
  const [displayedBansEnemy, setDisplayedBansEnemy] = useState([]);
  const [enemyNextIndex, setEnemyNextIndex] = useState(0);

  // Accordion states
  const [mapSectionOpen, setMapSectionOpen] = useState(true);
  const [teamSectionOpen, setTeamSectionOpen] = useState(false);
  const [banSectionOpen, setBanSectionOpen] = useState(false);
  const [pickSectionOpen, setPickSectionOpen] = useState(false);
  const [finalSectionOpen, setFinalSectionOpen] = useState(false);

  const [mapSummary, setMapSummary] = useState("");
  const [teamSummary, setTeamSummary] = useState("");
  const [banSummary, setBanSummary] = useState("");
  const [pickSummary, setPickSummary] = useState("");
  const [finalSummary, setFinalSummary] = useState("");

  // Store fetched brawler stats for selected map
  const [mapBrawlerStats, setMapBrawlerStats] = useState({});
  const [matchupSummary, setMatchupSummary] = useState(null);

  const [sortColumn, setSortColumn] = useState("team"); // default sort column
  const [sortDirection, setSortDirection] = useState("asc"); // or "desc"
  const [showSynergyInfo, setShowSynergyInfo] = useState(false);
  const [loadingResults, setLoadingResults] = useState(false);

  // Function to handle sorting
  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const getSortedBrawlers = () => {
    const yourTeam = matchupSummary?.your_team_brawler_stats ?? [];
    const enemyTeam = matchupSummary?.enemy_team_brawler_stats ?? [];
    const allBrawlers = [
      ...yourTeam.map((b) => ({ ...b, team: "Ally" })),
      ...enemyTeam.map((b) => ({ ...b, team: "Enemy" })),
    ];

    const compare = (a, b) => {
      let valA, valB;
      switch (sortColumn) {
        case "brawler":
          valA = a.brawler.toUpperCase();
          valB = b.brawler.toUpperCase();
          break;
        case "team":
          valA = a.team === "Ally" ? 0 : 1;
          valB = b.team === "Ally" ? 0 : 1;
          break;
        case "win_rate":
          valA = a.win_rate ?? 0;
          valB = b.win_rate ?? 0;
          break;
        case "star_player_rate":
          valA = a.star_player_rate ?? 0;
          valB = b.star_player_rate ?? 0;
          break;
        case "matches":
          valA = a.matches ?? 0;
          valB = b.matches ?? 0;
          break;
        default:
          valA = a.team;
          valB = b.team;
      }
      if (valA < valB) return sortDirection === "asc" ? -1 : 1;
      if (valA > valB) return sortDirection === "asc" ? 1 : -1;
      return 0;
    };
    return allBrawlers.sort(compare);
  };

  const sortedBrawlers = matchupSummary ? getSortedBrawlers() : [];

  const toggleSection = (section) => {
    if (section === "map" && mapSummary) setMapSectionOpen(!mapSectionOpen);
    if (section === "team" && teamSummary) setTeamSectionOpen(!teamSectionOpen);
    if (section === "ban" && banSummary) setBanSectionOpen(!banSectionOpen);
    if (section === "pick" && pickSummary) setPickSectionOpen(!pickSectionOpen);
    if (section === "final" && finalSummary)
      setFinalSectionOpen(!finalSectionOpen);
  };

  useEffect(() => {
    const fetchMaps = async () => {
      const res = await fetch(`${BACKEND_BASE_URL}/api/ranked/maps`);
      const data = await res.json();
      setMaps(Array.isArray(data) ? data : []);
    };
    fetchMaps();
  }, [playerTag]);

  useEffect(() => {
    const fetchAllBrawlers = async () => {
      const res = await fetch(`${BACKEND_BASE_URL}/api/all_brawlers`);
      const data = await res.json();
      setAllBrawlers(Array.isArray(data) ? data : []);
    };
    const fetchOwnedBrawlers = async () => {
      const res = await fetch(
        `${BACKEND_BASE_URL}/api/player_brawlers?player_tag=${encodeURIComponent(
          playerTag
        )}`
      );
      const data = await res.json();
      setOwnedBrawlers(Array.isArray(data) ? data : []);
    };
    fetchAllBrawlers();
    fetchOwnedBrawlers();
  }, [playerTag]);

  const renderHeader = (columnName, displayName) => {
    const isActive = sortColumn === columnName;
    const arrow = isActive ? (sortDirection === "asc" ? "▲" : "▼") : "";
    const style = {
      borderBottom: "2px solid #333",
      padding: "10px",
      cursor: "pointer",
      color: isActive ? "green" : "inherit",
      userSelect: "none",
      textAlign: columnName === "brawler" ? "left" : "center",
    };
    return (
      <th style={style} onClick={() => handleSort(columnName)}>
        {displayName} {arrow}
      </th>
    );
  };

  const fetchMapBrawlers = async (mapName) => {
    if (!mapName) return;
    const res = await fetch(
      `${BACKEND_BASE_URL}/api/map/${encodeURIComponent(mapName)}/brawlers`
    );
    const data = await res.json();
    const statsMap = {};
    data.forEach((b) => {
      statsMap[b.brawler.toUpperCase()] = b;
    });
    setMapBrawlerStats(statsMap);
  };

  const handleMapSelect = (map) => {
    if (selectedMap && selectedMap.name === map.name) {
      setSelectedMap(null);
      return;
    }
    setSelectedMap(map);
    setMapSummary(`${map.name} (${map.mode})`);
    setMapSectionOpen(false);
    setTeamSectionOpen(true);
    setPhase("choose_color");

    fetchMapBrawlers(map.name);
  };

  const filteredMaps = maps.filter((m) =>
    m.name.toLowerCase().includes(mapSearch.toLowerCase())
  );

  const fetchBanSuggestions = async (isAlly = true) => {
    const body = { map_name: selectedMap.name, min_battles: 5, top_n: 15 };
    const res = await fetch(`${BACKEND_BASE_URL}/api/ranked/bans`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    const data = await res.json();
    let bans = Array.isArray(data) ? data : [];
    const alreadyBanned = isAlly ? myBans : enemyBans;
    bans = bans.filter(
      (b) =>
        !alreadyBanned.map((ban) => ban.toUpperCase()).includes(b.toUpperCase())
    );
    if (isAlly) {
      setAllSuggestedBans(bans);
      setDisplayedBans(bans.slice(0, 10));
      setAllyNextIndex(10);
    } else {
      setAllSuggestedBansEnemy(bans);
      setDisplayedBansEnemy(bans.slice(0, 10));
      setEnemyNextIndex(10);
    }
  };

  const startBanPhase = async (color) => {
    setTeamColor(color);
    setTeamSummary(color === "blue" ? "First Pick" : "Second Pick");
    // Team color selected, now show player index selector after this section
  };

  const confirmTeamSelection = async () => {
    // Now that team color and playerIndex chosen, move on to ban phase
    teamSectionOpen && setTeamSectionOpen(false);

    await fetchBanSuggestions(true);
    await fetchBanSuggestions(false);

    setPhase("ban_phase");
    setBanSectionOpen(true);
  };

  const handleBan = async (brawler, isAllyBan = true) => {
    if (isAllyBan) {
      if (myBans.length >= 3 || myBans.includes(brawler)) return;
      setMyBans((prev) => [...prev, brawler]);
      await fetchBanSuggestions(true);
    } else {
      if (enemyBans.length >= 3 || enemyBans.includes(brawler)) return;
      setEnemyBans((prev) => [...prev, brawler]);
      await fetchBanSuggestions(false);
    }
  };

  const filteredBrawlersForBan = () => {
    const banned = myBans.map((b) => b.toUpperCase());
    if (!brawlerSearch.trim()) {
      return displayedBans.filter((b) => !banned.includes(b.toUpperCase()));
    } else {
      return allBrawlers
        .filter(
          (b) =>
            b.toLowerCase().includes(brawlerSearch.toLowerCase()) &&
            !banned.includes(b.toUpperCase())
        )
        .slice(0, 25);
    }
  };

  const filteredBrawlersForEnemyBan = () => {
    const banned = enemyBans.map((b) => b.toUpperCase());
    if (!enemyBrawlerSearch.trim()) {
      return displayedBansEnemy.filter(
        (b) => !banned.includes(b.toUpperCase())
      );
    } else {
      return allBrawlers
        .filter(
          (b) =>
            b.toLowerCase().includes(enemyBrawlerSearch.toLowerCase()) &&
            !banned.includes(b.toUpperCase())
        )
        .slice(0, 25);
    }
  };

  const removeBan = (brawler, isAlly = true) => {
    if (isAlly) {
      setMyBans(myBans.filter((mb) => mb !== brawler));
    } else {
      setEnemyBans(enemyBans.filter((eb) => eb !== brawler));
    }
  };

  const confirmBans = () => {
    setBanSummary(
      <>
        Your Bans: {myBans.join(", ")}
        <br />
        <br />
        Enemy Bans: {enemyBans.join(", ")}
      </>
    );
    setBanSectionOpen(false);
    fetchPickSuggestions();
    setPickSectionOpen(true);
    setPhase("pick_phase");
  };

  const fetchPickSuggestions = async () => {
    const body = {
      map_name: selectedMap.name,
      ally_picks: allyPicks,
      enemy_picks: enemyPicks,
      bans: [...myBans, ...enemyBans],
      pick_order: teamColor === "blue" ? "first" : "second",
      min_battles: 5,
      top_n: 20,
      player_tag: playerTag,
    };
    const res = await fetch(`${BACKEND_BASE_URL}/api/ranked/picks`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    const data = await res.json();
    setSuggestedPicksUser(Array.isArray(data) ? data : []);
    setSuggestedPicksEnemy(Array.isArray(data) ? data : []);
  };

  const pickSequenceIfBlue = [
    "ally",
    "enemy",
    "enemy",
    "ally",
    "ally",
    "enemy",
  ];
  const pickSequenceIfRed = ["enemy", "ally", "ally", "enemy", "enemy", "ally"];
  const sequence =
    teamColor === "blue" ? pickSequenceIfBlue : pickSequenceIfRed;

  const totalPicks = allyPicks.length + enemyPicks.length;
  let currentPicker = sequence[totalPicks] || "ally";

  const availableUserBrawlers = () => {
    let pool = suggestedPicksUser;
    if (pickPhaseUserSearch.trim()) {
      pool = allBrawlers
        .map((b) => {
          const found = suggestedPicksUser.find(
            (sb) => sb.brawler.toUpperCase() === b.toUpperCase()
          );
          return (
            found || {
              brawler: b,
              wilson_score: 0,
              wins: 0,
              total: 0,
            }
          );
        })
        .filter((sb) =>
          sb.brawler.toLowerCase().includes(pickPhaseUserSearch.toLowerCase())
        );
    }

    const unavailable = [
      ...myBans,
      ...enemyBans,
      ...allyPicks,
      ...enemyPicks,
    ].map((x) => x.toUpperCase());

    // If playerIndex != "1", assume all brawlers unlocked
    if (playerIndex !== "1") {
      pool = pool.filter(
        (sb) => !unavailable.includes(sb.brawler.toUpperCase())
      );
    } else {
      // playerIndex=1 => filter by ownedBrawlers as before
      pool = pool.filter(
        (sb) =>
          !unavailable.includes(sb.brawler.toUpperCase()) &&
          ownedBrawlers
            .map((ob) => ob.toUpperCase())
            .includes(sb.brawler.toUpperCase())
      );
    }

    if (!pickPhaseUserSearch.trim()) {
      pool = pool.slice(0, 10);
    }

    return pool;
  };

  const availableEnemyBrawlers = () => {
    let pool = suggestedPicksEnemy;
    if (pickPhaseEnemySearch.trim()) {
      pool = allBrawlers
        .map((b) => {
          const found = suggestedPicksEnemy.find(
            (se) => se.brawler.toUpperCase() === b.toUpperCase()
          );
          return (
            found || {
              brawler: b,
              wilson_score: 0,
              wins: 0,
              total: 0,
            }
          );
        })
        .filter((se) =>
          se.brawler.toLowerCase().includes(pickPhaseEnemySearch.toLowerCase())
        );
    }

    const unavailable = [
      ...myBans,
      ...enemyBans,
      ...allyPicks,
      ...enemyPicks,
    ].map((x) => x.toUpperCase());

    // For enemy picks, we assume all brawlers unlocked anyway
    pool = pool.filter((se) => !unavailable.includes(se.brawler.toUpperCase()));

    if (!pickPhaseEnemySearch.trim()) {
      pool = pool.slice(0, 10);
    }
    return pool;
  };

  const pickBrawler = async (brawler, isAlly) => {
    let updatedAllyPicks = allyPicks;
    let updatedEnemyPicks = enemyPicks;

    if (isAlly && allyPicks.length < 3) {
      updatedAllyPicks = [...allyPicks, brawler];
      setAllyPicks(updatedAllyPicks);
    } else if (!isAlly && enemyPicks.length < 3) {
      updatedEnemyPicks = [...enemyPicks, brawler];
      setEnemyPicks(updatedEnemyPicks);
    }

    if (updatedAllyPicks.length === 3 && updatedEnemyPicks.length === 3) {
      setPickSectionOpen(false);
      setFinalSectionOpen(true);
      setLoadingResults(true);
      setTimeout(async () => {
        const body = {
          your_team: updatedAllyPicks,
          enemy_team: updatedEnemyPicks,
          min_matches: 5,
        };
        const res = await fetch(`${BACKEND_BASE_URL}/api/ranked/predict`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(body),
        });
        const data = await res.json();
        setLoadingResults(false);

        if (data.win_percentage) {
          setPredictedWinPercentage(data.win_percentage);
        } else {
          setPredictedWinPercentage("Insufficient data for prediction.");
        }

        setPickSummary(
          <>
            Your Team: {updatedAllyPicks.join(", ")}
            <br />
            <br />
            Enemy Team: {updatedEnemyPicks.join(", ")}
          </>
        );
        setFinalSummary(
          typeof predictedWinPercentage === "number"
            ? `${predictedWinPercentage}%`
            : "N/A"
        );
        // fetchMatchupSummary();
      }, 1200);
    } else {
      fetchPickSuggestions();
    }
  };

  const removePick = (brawler, isAlly = true) => {
    if (isAlly) {
      setAllyPicks(allyPicks.filter((ap) => ap !== brawler));
    } else {
      setEnemyPicks(enemyPicks.filter((ep) => ep !== brawler));
    }
  };

  function getBrawlerData(brawler) {
    const upper = brawler.toUpperCase();
    if (mapBrawlerStats[upper]) {
      const stat = mapBrawlerStats[upper];
      let winRateNum = stat.win_rate;
      if (typeof winRateNum !== "number") {
        winRateNum = 0;
      }
      const winRate = winRateNum.toFixed(2);
      const timesPlayed = stat.total;
      return {
        iconURL: BRAWLER_IMAGES[brawler.toLowerCase()] || "",
        winRate,
        timesPlayed: timesPlayed || 0,
      };
    } else {
      return {
        iconURL: BRAWLER_IMAGES[brawler.toLowerCase()] || "",
        winRate: "N/A",
        timesPlayed: 0,
      };
    }
  }

  const renderBrawlerTile = (
    brawler,
    isBanOrPick = false,
    onActionClick = null,
    actionLabel = null
  ) => {
    const { iconURL, winRate, timesPlayed } = getBrawlerData(brawler);
    return (
      <div key={brawler} className="brawler-tile">
        <img src={iconURL} alt={brawler} className="brawler-icon" />
        <div className="brawler-info">
          <div className="brawler-name">{brawler}</div>
          <div className="brawler-stats">
            <span>Win Rate: {winRate}%</span>
            <br />
            <span>Games Played: {timesPlayed}</span>
          </div>
        </div>
        {isBanOrPick && onActionClick && (
          <button onClick={onActionClick} className="ban-button">
            {actionLabel}
          </button>
        )}
      </div>
    );
  };

  const fetchMatchupSummary = async () => {
    const body = {
      map_name: selectedMap.name,
      your_team: allyPicks,
      enemy_team: enemyPicks,
    };

    const res = await fetch(`${BACKEND_BASE_URL}/api/ranked/matchup_summary`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });

    const data = await res.json();
    setMatchupSummary(data);
  };

  const backToHome = () => {
    setPhase("select_map");
    setMapSectionOpen(true);
    setTeamSectionOpen(false);
    setBanSectionOpen(false);
    setPickSectionOpen(false);
    setFinalSectionOpen(false);

    setSelectedMap(null);
    setTeamColor("");
    setMyBans([]);
    setEnemyBans([]);
    setAllyPicks([]);
    setEnemyPicks([]);
    setMapSummary("");
    setTeamSummary("");
    setBanSummary("");
    setPickSummary("");
    setFinalSummary("");
    setPredictedWinPercentage(null);
    setMatchupSummary(null);

    setMapSearch("");
    setBrawlerSearch("");
    setEnemyBrawlerSearch("");
    setPickPhaseUserSearch("");
    setPickPhaseEnemySearch("");
  };

  useEffect(() => {
    if (finalSectionOpen && allyPicks.length === 3 && enemyPicks.length === 3) {
      fetchMatchupSummary();
    }
  }, [finalSectionOpen, allyPicks, enemyPicks]);

  const renderChosenBrawlers = (brawlers, removeFn) => {
    return (
      <div className="brawler-grid">
        {brawlers.map((b) =>
          renderBrawlerTile(b, true, () => removeFn(b), "Remove")
        )}
      </div>
    );
  };

  const renderSelectedBrawlers = (brawlers, removeFn) => {
    return (
      <div className="brawler-grid-selected">
        {brawlers.map((b) =>
          renderBrawlerTile(b, true, () => removeFn(b), "Remove")
        )}
      </div>
    );
  };

  const renderFinalTeam = (teamName, picks) => {
    return (
      <div className="final-matchup-team">
        <h4 className="final-pick-team-names">{teamName}</h4>
        <div className="final-team-brawlers">
          {picks.map((brawler) => {
            const { iconURL } = getBrawlerData(brawler);
            return (
              <div key={brawler} className="final-brawler-icon">
                <img src={iconURL} alt={brawler} />
                <span>{brawler}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div>
      <Banner playerTag={playerTag} onLogout={onLogout} />
      <div className="mapmaster-topNav">
        <NavLinks />
      </div>

      {/* SECTION 1: Map Selection */}
      <div className="section-container">
        <div className="section-header" onClick={() => toggleSection("map")}>
          <h2 className="ranked-title-section-header">1. Map Selection</h2>
          {mapSummary && <span className="section-summary">{mapSummary}</span>}
        </div>
        {mapSectionOpen && (
          <div className="section-content">
            <h2 style={{ marginTop: "20px" }}>Select A Map For Ranked</h2>
            <div className="map-selection-holder centered-grid">
              {selectedMap && (
                <div
                  className="mapmaster-mapItemSelectedAtTop"
                  onClick={() => handleMapSelect(selectedMap)}
                >
                  {selectedMap.name} ({selectedMap.mode})
                </div>
              )}
            </div>
            <div className="search-maps-outermost-container centered-content">
              {selectedMap === null && (
                <>
                  <div style={{ marginBottom: "25px" }}>
                    <input
                      type="text"
                      placeholder="Search Map..."
                      value={mapSearch}
                      onChange={(e) => setMapSearch(e.target.value)}
                      className="large-rounded-input"
                    />
                  </div>
                  <div className="map-list-container centered-grid">
                    {filteredMaps.length > 0 ? (
                      filteredMaps.map((m) => (
                        <div
                          key={m.name}
                          className={
                            m === selectedMap
                              ? "mapmaster-mapItemSelected"
                              : "mapmaster-mapItem"
                          }
                          onClick={() => handleMapSelect(m)}
                        >
                          {m.name} ({m.mode})
                        </div>
                      ))
                    ) : (
                      <p>sorry nothing to see here</p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      {/* SECTION 2: Team Selection */}
      <div className="section-container">
        <div className="section-header" onClick={() => toggleSection("team")}>
          <h2 className="ranked-title-section-header">2. Team Selection</h2>
          {teamSummary && (
            <span className="section-summary">{teamSummary}</span>
          )}
        </div>
        {teamSectionOpen && (
          <div className="section-content centered-content">
            <h2>Who picks first?</h2>
            <div className="team-color-select centered-grid">
              <div
                onClick={() => startBanPhase("blue")}
                className="blue-circle"
              >
                BLUE
              </div>
              <div onClick={() => startBanPhase("red")} className="red-circle">
                RED
              </div>
            </div>

            {teamColor && (
              <div
                className="player-index-selection"
                style={{ marginTop: "20px" }}
              >
                <h3>Select Your Player Position:</h3>
                <select
                  value={playerIndex}
                  onChange={(e) => setPlayerIndex(e.target.value)}
                  className="large-rounded-input"
                >
                  <option value="1">Player 1</option>
                  <option value="2">Player 2</option>
                  <option value="3">Player 3</option>
                </select>
              </div>
            )}

            {teamColor && (
              <button
                className="confirm-bans-or-picks"
                style={{ marginTop: "10px" }}
                onClick={confirmTeamSelection}
              >
                Confirm
              </button>
            )}
          </div>
        )}
      </div>

      {/* SECTION 3: Ban Brawlers */}
      <div className="section-container">
        <div className="section-header" onClick={() => toggleSection("ban")}>
          <h2 className="ranked-title-section-header">3. Ban Brawlers</h2>
          {banSummary && <span className="section-summary">{banSummary}</span>}
        </div>
        {banSectionOpen && (
          <>
            <div className="section-content-ban">
              <div className="brawler-grid-container">
                <h1 className="centered-text">
                  Your Bans on: {selectedMap?.name}
                </h1>

                <h2 className="centered-text">
                  Search Brawlers to Ban (Your Team):
                </h2>
                <div className="centered-content">
                  <input
                    type="text"
                    placeholder="Search for Brawler..."
                    value={brawlerSearch}
                    onChange={(e) => setBrawlerSearch(e.target.value)}
                    className="large-rounded-input"
                  />
                </div>
                {brawlerSearch.length > 0 ? (
                  <h2 className="deafult-search-text">
                    Suggested Brawlers to ban:
                  </h2>
                ) : (
                  <h2 className="deafult-search-text">
                    Top 10 suggested Brawlers to ban:
                  </h2>
                )}
                <div className="brawler-grid">
                  {filteredBrawlersForBan().length > 0 ? (
                    filteredBrawlersForBan().map((b) =>
                      renderBrawlerTile(
                        b,
                        true,
                        () => handleBan(b, true),
                        "Ban"
                      )
                    )
                  ) : (
                    <p>sorry nothing to see here</p>
                  )}
                </div>

                <h3 className="centered-text">Your Chosen Bans:</h3>

                {renderChosenBrawlers(myBans, (b) => removeBan(b, true))}
              </div>
              <div className="brawler-grid-container">
                <h1 className="centered-text">Enemy Bans</h1>
                <h2 className="centered-text">
                  Search Brawlers for Enemy Ban:
                </h2>
                <div className="centered-content">
                  <input
                    type="text"
                    placeholder="Search for Brawler..."
                    value={enemyBrawlerSearch}
                    onChange={(e) => setEnemyBrawlerSearch(e.target.value)}
                    className="large-rounded-input"
                  />
                </div>
                <>
                  {enemyBrawlerSearch.length > 0 ? (
                    <h2 className="deafult-search-text">
                      Suggested Brawlers to ban:
                    </h2>
                  ) : (
                    <h2 className="deafult-search-text">
                      Top 10 suggested Brawlers to ban:
                    </h2>
                  )}
                  <div className="brawler-grid centered-grid">
                    {filteredBrawlersForEnemyBan().length > 0 ? (
                      filteredBrawlersForEnemyBan().map((b) =>
                        renderBrawlerTile(
                          b,
                          true,
                          () => handleBan(b, false),
                          "Ban"
                        )
                      )
                    ) : (
                      <span>sorry nothing to see here</span>
                    )}
                  </div>
                </>
                <h3 className="centered-text">Enemy Chosen Bans:</h3>

                {renderChosenBrawlers(enemyBans, (b) => removeBan(b, false))}
              </div>
            </div>
            {myBans.length === 3 && enemyBans.length === 3 && (
              <div className="centered-content" style={{ marginTop: "10px" }}>
                <button className="confirm-bans-or-picks" onClick={confirmBans}>
                  Confirm Bans
                </button>
              </div>
            )}
          </>
        )}
      </div>

      {/* SECTION 4: Pick Brawlers */}
      <div className="section-container">
        <div className="section-header" onClick={() => toggleSection("pick")}>
          <h2 className="ranked-title-section-header">4. Pick Brawlers</h2>
          {pickSummary && (
            <span className="section-summary">{pickSummary}</span>
          )}
        </div>
        {pickSectionOpen && (
          <div className="section-content-pick-phase">
            <h2>Picking Phase</h2>
            {selectedMap && (
              <div className="mapmaster-mapItemSelectedAtTop">
                {selectedMap.name} ({selectedMap.mode})
              </div>
            )}
            <div className="chosen-brawler-lists-container">
              <div className="selected-brawler-list-container-test">
                <h3>Ally Picks:</h3>
                {renderSelectedBrawlers(allyPicks, (b) => removePick(b, true))}
              </div>
              <div className="selected-brawler-list-container-test">
                {" "}
                <h3>Enemy Picks:</h3>
                {renderSelectedBrawlers(enemyPicks, (b) =>
                  removePick(b, false)
                )}
              </div>
            </div>

            <h2>Current Bans: {[...myBans, ...enemyBans].join(", ")}</h2>

            {currentPicker === "ally" ? (
              <h1>Your Team's Turn</h1>
            ) : (
              <h1>Enemy's Turn</h1>
            )}
            <p>Search:</p>
            {currentPicker === "ally" ? (
              <>
                <input
                  type="text"
                  placeholder="Search for brawler..."
                  value={pickPhaseUserSearch}
                  onChange={(e) => setPickPhaseUserSearch(e.target.value)}
                  className={`large-rounded-input ${
                    currentPicker === "ally" ? "blue-highlight" : ""
                  }`}
                />
                <div className="brawler-grid">
                  {availableUserBrawlers().length > 0 ? (
                    availableUserBrawlers().map((sb) =>
                      renderBrawlerTile(
                        sb.brawler,
                        true,
                        () => pickBrawler(sb.brawler, true),
                        "Select"
                      )
                    )
                  ) : (
                    <p>sorry nothing to see here</p>
                  )}
                </div>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Search for brawler..."
                  value={pickPhaseEnemySearch}
                  onChange={(e) => setPickPhaseEnemySearch(e.target.value)}
                  className={`large-rounded-input ${
                    currentPicker === "enemy" ? "red-highlight" : ""
                  }`}
                />
                <div className="brawler-grid centered-grid">
                  {availableEnemyBrawlers().length > 0 ? (
                    availableEnemyBrawlers().map((sb) =>
                      renderBrawlerTile(
                        sb.brawler,
                        true,
                        () => pickBrawler(sb.brawler, false),
                        "Pick"
                      )
                    )
                  ) : (
                    <p>sorry nothing to see here</p>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      {/* Animated */}
      {finalSectionOpen && (
        <div className="section-content final-results-container">
          <div className="animation-overlay">
            <div className="animation-container-small">
              {/* Map name and mode, teams, etc. */}
              <h3
                style={{
                  color: "#fff",
                  marginBottom: "10px",
                  fontSize: "1.8em",
                }}
              >
                {selectedMap.name} ({selectedMap.mode})
              </h3>
              <div className="final-matchup-teams">
                {renderFinalTeam("Your Team", allyPicks)}
                {renderFinalTeam("Enemy Team", enemyPicks)}
              </div>
              {/* <div className="lightning-container">
                <div className="lightning white tl"></div>
                <div className="lightning red tl"></div>

                <div className="lightning white tr"></div>
                <div className="lightning red tr"></div>

                <div className="lightning white bl"></div>
                <div className="lightning red bl"></div>

                <div className="lightning white br"></div>
                <div className="lightning red br"></div>
              </div> */}
              <h1 className="loading-message-big">Good luck battling!</h1>
            </div>
          </div>
          <h2>Predicted Win Percentage</h2>
          <p>
            {typeof predictedWinPercentage === "number"
              ? predictedWinPercentage.toFixed(2) + "%"
              : predictedWinPercentage}
          </p>

          {matchupSummary && (
            <>
              {/* No extra ( here */}
              <h3
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                Team Synergy
                <div
                  className="synergy-icon-container"
                  style={{ position: "relative", marginLeft: "10px" }}
                >
                  <span
                    className="synergy-info-icon"
                    onClick={() => setShowSynergyInfo(!showSynergyInfo)}
                    style={{
                      display: "inline-block",
                      width: "30px",
                      height: "30px",
                      background: "black",
                      color: "white",
                      borderRadius: "50%",
                      textAlign: "center",
                      lineHeight: "30px",
                      cursor: "pointer",
                      fontSize: "18px",
                      fontWeight: "bold",
                      userSelect: "none",
                    }}
                  >
                    i
                  </span>
                  {showSynergyInfo && (
                    <div
                      style={{
                        position: "absolute",
                        top: "40px",
                        left: "0",
                        background: "white",
                        color: "black",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        padding: "10px",
                        width: "250px",
                        zIndex: 999,
                        boxShadow: "0 0 10px rgba(0,0,0,0.3)",
                      }}
                    >
                      <strong>What is Team Synergy?</strong>
                      <p style={{ fontSize: "0.9em", marginTop: "5px" }}>
                        Team synergy is derived from historical matches
                        featuring your chosen team composition on this map. A
                        higher synergy score means the team typically performs
                        well together, resulting in more consistent wins. Use
                        this info to understand the strengths of your chosen
                        brawlers as a unit.
                      </p>
                    </div>
                  )}
                </div>
              </h3>
              <p>
                Your Team Synergy:{" "}
                {typeof matchupSummary.your_team_synergy === "number"
                  ? matchupSummary.your_team_synergy.toFixed(2) + "%"
                  : "N/A"}
              </p>
              <p>
                Enemy Team Synergy:{" "}
                {typeof matchupSummary.enemy_team_synergy === "number"
                  ? matchupSummary.enemy_team_synergy.toFixed(2) + "%"
                  : "N/A"}
              </p>

              <h3>All Brawler Stats</h3>
              <table
                className="brawler-stats-table"
                style={{
                  borderCollapse: "collapse",
                  margin: "20px auto",
                  maxWidth: "800px",
                  width: "100%",
                }}
              >
                <thead>
                  <tr>
                    {renderHeader("brawler", "Brawler")}
                    {renderHeader("team", "Team")}
                    {renderHeader("win_rate", "Win Rate")}
                    {renderHeader("star_player_rate", "Star Player Rate")}
                    {renderHeader("matches", "Matches Played")}
                  </tr>
                </thead>
                <tbody>
                  {sortedBrawlers.map((b) => {
                    const winRate =
                      b.win_rate != null ? b.win_rate.toFixed(2) + "%" : "N/A";
                    const starPlayerRate =
                      b.star_player_rate != null
                        ? b.star_player_rate.toFixed(2) + "%"
                        : "N/A";
                    const bgColor = b.team === "Ally" ? "#cce0ff" : "#ffcccc";

                    return (
                      <tr key={b.brawler} style={{ backgroundColor: bgColor }}>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc",
                            padding: "8px",
                          }}
                        >
                          {b.brawler}
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          {b.team}
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          {winRate}
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          {starPlayerRate}
                        </td>
                        <td
                          style={{
                            borderBottom: "1px solid #ccc",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          {b.matches}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              {matchupSummary.top_threat && (
                <>
                  <h3>Top Threat</h3>
                  <p>
                    {matchupSummary.top_threat.brawler} is historically strong.
                    Consider adjusting your playstyle to counter them.
                  </p>
                </>
              )}

              <p>{matchupSummary.notes}</p>
            </>
          )}
          <button className="start-over-button" onClick={backToHome}>
            Start Over
          </button>
        </div>
      )}
    </div>
  );
}

export default RankedAssistant;
