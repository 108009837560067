// src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import MapMaster from "./pages/MapMaster";
import RankedAssistant from "./pages/RankedAssistant";
import RedirectIndex from "./components/RedirectIndex"; // Import the Redirect component
import "./App.css";

function App() {
  const [playerTag, setPlayerTag] = useState(
    localStorage.getItem("playerTag") || ""
  );

  useEffect(() => {
    if (playerTag) {
      localStorage.setItem("playerTag", playerTag);
    } else {
      localStorage.removeItem("playerTag");
    }
  }, [playerTag]);

  const handleLogin = (tag) => {
    setPlayerTag(tag);
  };

  const handleLogout = () => {
    setPlayerTag("");
  };

  return (
    <div className="App">
      <Router>
        <RedirectIndex /> {/* Add the Redirect component */}
        <Routes>
          <Route path="/login" element={<Login onLogin={handleLogin} />} />
          <Route
            path="/map-master"
            element={
              playerTag ? (
                <MapMaster playerTag={playerTag} onLogout={handleLogout} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/ranked-assistant"
            element={
              playerTag ? (
                <RankedAssistant
                  playerTag={playerTag}
                  onLogout={handleLogout}
                />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/"
            element={
              playerTag ? (
                <Navigate to="/map-master" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
