// src/pages/MapMaster.js
import React, { useEffect, useState } from "react";
import Banner from "../components/Banner";
import NavLinks from "../components/NavLinks";
import "./MapMaster.css"; // Import the dedicated CSS file
import { FaSort, FaSortUp, FaSortDown, FaInfoCircle } from "react-icons/fa";

// Define the backend base URL
// const BACKEND_BASE_URL = "http://127.0.0.1:8000";
// const BACKEND_BASE_URL = "https://api.brawl360.com";
const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

// Mapping of brawler names to their image URLs
const BRAWLER_IMAGES = {
  shelly: `${BACKEND_BASE_URL}/static/28000003.png`,
  colt: `${BACKEND_BASE_URL}/static/28000004.png`,
  bull: `${BACKEND_BASE_URL}/static/28000010.png`,
  brock: `${BACKEND_BASE_URL}/static/28000005.png`,
  rico: `${BACKEND_BASE_URL}/static/28000011.png`,
  spike: `${BACKEND_BASE_URL}/static/28000016.png`,
  barley: `${BACKEND_BASE_URL}/static/28000012.png`,
  jessie: `${BACKEND_BASE_URL}/static/28000006.png`,
  nita: `${BACKEND_BASE_URL}/static/28000007.png`,
  dynamike: `${BACKEND_BASE_URL}/static/28000008.png`,
  "el primo": `${BACKEND_BASE_URL}/static/28000009.png`,
  mortis: `${BACKEND_BASE_URL}/static/28000014.png`,
  crow: `${BACKEND_BASE_URL}/static/28000017.png`,
  poco: `${BACKEND_BASE_URL}/static/28000013.png`,
  bo: `${BACKEND_BASE_URL}/static/28000015.png`,
  piper: `${BACKEND_BASE_URL}/static/28000018.png`,
  pam: `${BACKEND_BASE_URL}/static/28000028.png`,
  tara: `${BACKEND_BASE_URL}/static/28000029.png`,
  darryl: `${BACKEND_BASE_URL}/static/28000034.png`,
  penny: `${BACKEND_BASE_URL}/static/28000035.png`,
  frank: `${BACKEND_BASE_URL}/static/28000036.png`,
  gene: `${BACKEND_BASE_URL}/static/28000038.png`,
  tick: `${BACKEND_BASE_URL}/static/28000042.png`,
  leon: `${BACKEND_BASE_URL}/static/28000037.png`,
  rosa: `${BACKEND_BASE_URL}/static/28000040.png`,
  carl: `${BACKEND_BASE_URL}/static/28000039.png`,
  bibi: `${BACKEND_BASE_URL}/static/28000041.png`,
  "8-bit": `${BACKEND_BASE_URL}/static/28000043.png`,
  sandy: `${BACKEND_BASE_URL}/static/28000044.png`,
  bea: `${BACKEND_BASE_URL}/static/28000046.png`,
  emz: `${BACKEND_BASE_URL}/static/28000045.png`,
  "mr. p": `${BACKEND_BASE_URL}/static/28000048.png`,
  max: `${BACKEND_BASE_URL}/static/28000047.png`,
  jacky: `${BACKEND_BASE_URL}/static/28000049.png`,
  gale: `${BACKEND_BASE_URL}/static/28000051.png`,
  nani: `${BACKEND_BASE_URL}/static/28000052.png`,
  sprout: `${BACKEND_BASE_URL}/static/28000050.png`,
  surge: `${BACKEND_BASE_URL}/static/28000053.png`,
  colette: `${BACKEND_BASE_URL}/static/28000054.png`,
  amber: `${BACKEND_BASE_URL}/static/28000055.png`,
  lou: `${BACKEND_BASE_URL}/static/28000056.png`,
  byron: `${BACKEND_BASE_URL}/static/28000057.png`,
  edgar: `${BACKEND_BASE_URL}/static/28000058.png`,
  ruffs: `${BACKEND_BASE_URL}/static/28000059.png`,
  stu: `${BACKEND_BASE_URL}/static/28000062.png`,
  belle: `${BACKEND_BASE_URL}/static/28000065.png`,
  squeak: `${BACKEND_BASE_URL}/static/28000066.png`,
  grom: `${BACKEND_BASE_URL}/static/28000080.png`,
  buzz: `${BACKEND_BASE_URL}/static/28000067.png`,
  griff: `${BACKEND_BASE_URL}/static/28000068.png`,
  ash: `${BACKEND_BASE_URL}/static/28000071.png`,
  meg: `${BACKEND_BASE_URL}/static/28000074.png`,
  lola: `${BACKEND_BASE_URL}/static/28000075.png`,
  fang: `${BACKEND_BASE_URL}/static/28000081.png`,
  eve: `${BACKEND_BASE_URL}/static/28000082.png`,
  janet: `${BACKEND_BASE_URL}/static/28000085.png`,
  bonnie: `${BACKEND_BASE_URL}/static/28000086.png`,
  otis: `${BACKEND_BASE_URL}/static/28000105.png`,
  sam: `${BACKEND_BASE_URL}/static/28000149.png`,
  gus: `${BACKEND_BASE_URL}/static/28000150.png`,
  buster: `${BACKEND_BASE_URL}/static/28000160.png`,
  chester: `${BACKEND_BASE_URL}/static/28000185.png`,
  gray: `${BACKEND_BASE_URL}/static/28000183.png`,
  mandy: `${BACKEND_BASE_URL}/static/28000184.png`,
  "r-t": `${BACKEND_BASE_URL}/static/28000245.png`,
  willow: `${BACKEND_BASE_URL}/static/28000246.png`,
  maisie: `${BACKEND_BASE_URL}/static/28000265.png`,
  hank: `${BACKEND_BASE_URL}/static/28000266.png`,
  cordelius: `${BACKEND_BASE_URL}/static/28000288.png`,
  doug: `${BACKEND_BASE_URL}/static/28000289.png`,
  pearl: `${BACKEND_BASE_URL}/static/28000319.png`,
  chuck: `${BACKEND_BASE_URL}/static/28000320.png`,
  charlie: `${BACKEND_BASE_URL}/static/28000333.png`,
  mico: `${BACKEND_BASE_URL}/static/28000381.png`,
  kit: `${BACKEND_BASE_URL}/static/28000382.png`,
  "larry & lawrie": `${BACKEND_BASE_URL}/static/28000387.png`,
  melodie: `${BACKEND_BASE_URL}/static/28000429.png`,
  angelo: `${BACKEND_BASE_URL}/static/28000430.png`,
  draco: `${BACKEND_BASE_URL}/static/28000465.png`,
  lily: `${BACKEND_BASE_URL}/static/28000464.png`,
  berry: `${BACKEND_BASE_URL}/static/28000532.png`,
  clancy: `${BACKEND_BASE_URL}/static/28000531.png`,
  moe: `${BACKEND_BASE_URL}/static/28000613.png`,
  kenji: `${BACKEND_BASE_URL}/static/28000593.png`,
  // "shade": "",
  // "juju": ""
};

function MapMaster({ playerTag, onLogout }) {
  const [maps, setMaps] = useState([]);
  const [selectedMap, setSelectedMap] = useState(null);
  const [brawlers, setBrawlers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loadingMaps, setLoadingMaps] = useState(true);
  const [mapsError, setMapsError] = useState(null);

  const [mapSource, setMapSource] = useState("current");

  // Sorting states
  const [brawlerSortColumn, setBrawlerSortColumn] = useState("wilson_score");
  const [brawlerSortDirection, setBrawlerSortDirection] = useState("desc");
  const [teamSortColumn, setTeamSortColumn] = useState("wilson_score");
  const [teamSortDirection, setTeamSortDirection] = useState("desc");

  // State for showing Wilson Score info
  const [showWilsonInfo, setShowWilsonInfo] = useState(false);
  const [useWilson, setUseWilson] = useState(false);

  useEffect(() => {
    const fetchMaps = async () => {
      setLoadingMaps(true);
      setMapsError(null);
      setSelectedMap(null);
      console.log(mapSource);

      try {
        const url =
          mapSource === "current"
            ? `${BACKEND_BASE_URL}/api/events/rotation`
            : `${BACKEND_BASE_URL}/api/events`;
        const res = await fetch(url);
        if (!res.ok) {
          throw new Error(
            `Failed to fetch events: ${res.status} ${res.statusText}`
          );
        }
        const data = await res.json();

        const uniqueMaps = Array.from(
          new Map(data.map((map) => [map.name, map])).values()
        );

        setMaps(uniqueMaps);
      } catch (err) {
        console.error("Error fetching events:", err);
        setMapsError(err.message);
      } finally {
        setLoadingMaps(false);
      }
    };

    fetchMaps();
  }, [mapSource]);

  const handleMapSelect = async (map) => {
    setSelectedMap(map);

    try {
      const brawlersRes = await fetch(
        `${BACKEND_BASE_URL}/api/map/${encodeURIComponent(map.name)}/brawlers`
      );
      const brawlersData = brawlersRes.ok ? await brawlersRes.json() : [];
      setBrawlers(brawlersData);
    } catch (err) {
      console.error("Error fetching brawlers:", err);
    }

    try {
      const teamsRes = await fetch(
        `${BACKEND_BASE_URL}/api/map/${encodeURIComponent(map.name)}/teams`
      );
      const teamsData = teamsRes.ok ? await teamsRes.json() : [];
      setTeams(teamsData);
    } catch (err) {
      console.error("Error fetching teams:", err);
    }
  };

  const sortData = (data, column, direction) => {
    return [...data].sort((a, b) => {
      let valA = a[column];
      let valB = b[column];
      if (typeof valA === "string") valA = valA.toUpperCase();
      if (typeof valB === "string") valB = valB.toUpperCase();

      if (valA < valB) return direction === "asc" ? -1 : 1;
      if (valA > valB) return direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  const sortedBrawlers = sortData(
    brawlers,
    brawlerSortColumn,
    brawlerSortDirection
  );
  const sortedTeams = sortData(teams, teamSortColumn, teamSortDirection);

  const toggleBrawlerSort = (column) => {
    if (brawlerSortColumn === column) {
      setBrawlerSortDirection(brawlerSortDirection === "asc" ? "desc" : "asc");
    } else {
      setBrawlerSortColumn(column);
      setBrawlerSortDirection("asc");
    }
  };

  const toggleTeamSort = (column) => {
    if (teamSortColumn === column) {
      setTeamSortDirection(teamSortDirection === "asc" ? "desc" : "asc");
    } else {
      setTeamSortColumn(column);
      setTeamSortDirection("asc");
    }
  };

  const renderSortIcon = (currentColumn, sortColumn, sortDirection) => {
    if (currentColumn === sortColumn) {
      return sortDirection === "asc" ? (
        <FaSortUp className="sort-icon-active" />
      ) : (
        <FaSortDown className="sort-icon-active" />
      );
    }
    // Show a default sort icon for non-selected columns
    return <FaSort className="sort-icon-inactive" />;
  };

  const getHeaderClass = (currentColumn, sortColumn) => {
    return currentColumn === sortColumn ? "sorted-header" : "";
  };

  return (
    <div className="mapmaster-container">
      <Banner playerTag={playerTag} onLogout={onLogout} />
      <div className="mapmaster-topNav">
        <NavLinks />
      </div>
      <div className="mapmaster-toggleContainer">
        <button
          className={`mapmaster-toggleButton ${
            mapSource === "current" ? "mapmaster-selected" : ""
          }`}
          onClick={() => setMapSource("current")}
        >
          Current Event Rotation
        </button>
        <button
          className={`mapmaster-toggleButton ${
            mapSource === "all" ? "mapmaster-selected" : ""
          }`}
          onClick={() => setMapSource("all")}
        >
          All Aggregated Maps
        </button>
      </div>
      <div className="mapmaster-container-maps">
        <div className="mapmaster-section">
          <div className="mapmaster-sectionHeader">
            <span className="mapmaster-sectionNumber">1.</span>
            <span>Map Selection</span>
          </div>
          {loadingMaps && <p>Loading Maps...</p>}
          {mapsError && <p className="error-text">Error: {mapsError}</p>}
          {!loadingMaps && !mapsError && maps.length === 0 && (
            <p>No maps available.</p>
          )}
          <div className="mapmaster-mapList">
            {maps.map((m) => (
              <div
                key={m.name}
                className={
                  m === selectedMap
                    ? "mapmaster-mapItemSelected"
                    : "mapmaster-mapItem"
                }
                onClick={() => handleMapSelect(m)}
              >
                {m.name} ({m.mode})
              </div>
            ))}
          </div>
        </div>
      </div>

      {selectedMap && (
        <>
          <div className="mapmaster-brawler-info-section">
            <div className="mapmaster-sectionHeader">
              <span className="mapmaster-sectionNumber">2.</span>
              <span>Brawler Recommendations (Scroll-{">"})</span>
            </div>
            <div className="mapmaster-tableContainer">
              {showWilsonInfo && (
                <div className="wilson-info-box">
                  <strong>About Wilson Score:</strong>
                  <br />
                  The Wilson Score is a statistical measure that provides a
                  conservative estimate of a win rate's confidence interval. A
                  higher Wilson Score suggests a higher confidence in the
                  observed win rate.
                </div>
              )}
              {sortedBrawlers.length === 0 ? (
                <p>No brawler data available</p>
              ) : (
                <table className="mapmaster-table">
                  <thead>
                    <tr>
                      <th
                        onClick={() => toggleBrawlerSort("brawler")}
                        className={getHeaderClass("brawler", brawlerSortColumn)}
                      >
                        Brawler{" "}
                        {renderSortIcon(
                          "brawler",
                          brawlerSortColumn,
                          brawlerSortDirection
                        )}
                      </th>
                      <th
                        onClick={() => toggleBrawlerSort("wins")}
                        className={getHeaderClass("wins", brawlerSortColumn)}
                      >
                        Wins{" "}
                        {renderSortIcon(
                          "wins",
                          brawlerSortColumn,
                          brawlerSortDirection
                        )}
                      </th>
                      <th
                        onClick={() => toggleBrawlerSort("total")}
                        className={getHeaderClass("total", brawlerSortColumn)}
                      >
                        Games Played{" "}
                        {renderSortIcon(
                          "total",
                          brawlerSortColumn,
                          brawlerSortDirection
                        )}
                      </th>
                      <th
                        onClick={() =>
                          toggleBrawlerSort(
                            useWilson ? "wilson_score" : "win_rate"
                          )
                        }
                        className={getHeaderClass(
                          useWilson ? "wilson_score" : "win_rate",
                          brawlerSortColumn
                        )}
                      >
                        Win (%)
                        {renderSortIcon(
                          useWilson ? "wilson_score" : "win_rate",
                          brawlerSortColumn,
                          brawlerSortDirection
                        )}
                        {useWilson && (
                          <FaInfoCircle
                            className="info-icon"
                            onClick={() => setShowWilsonInfo(!showWilsonInfo)}
                          />
                        )}
                      </th>
                      <th
                        onClick={() => toggleBrawlerSort("star_player_count")}
                        className={getHeaderClass(
                          "star_player_count",
                          brawlerSortColumn
                        )}
                      >
                        Star Player Count{" "}
                        {renderSortIcon(
                          "star_player_count",
                          brawlerSortColumn,
                          brawlerSortDirection
                        )}
                      </th>
                      <th
                        onClick={() => toggleBrawlerSort("star_player_rate")}
                        className={getHeaderClass(
                          "star_player_rate",
                          brawlerSortColumn
                        )}
                      >
                        Star Player Rate (%){" "}
                        {renderSortIcon(
                          "star_player_rate",
                          brawlerSortColumn,
                          brawlerSortDirection
                        )}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedBrawlers.map((b, index) => (
                      <tr
                        key={b.brawler}
                        className={
                          index % 2 === 0
                            ? "mapmaster-evenRow"
                            : "mapmaster-oddRow"
                        }
                      >
                        <td className="mapmaster-brawlerCell">
                          <img
                            src={BRAWLER_IMAGES[b.brawler.toLowerCase()]}
                            alt={b.brawler}
                            className="mapmaster-brawlerImage"
                          />
                          {b.brawler}
                        </td>
                        <td className="mapmaster-centerAlign">{b.wins}</td>
                        <td className="mapmaster-centerAlign">{b.total}</td>
                        <td className="mapmaster-centerAlign">
                          {useWilson
                            ? b.wilson_score !== undefined &&
                              b.wilson_score !== null
                              ? b.wilson_score.toFixed(2)
                              : "N/A"
                            : b.win_rate !== undefined && b.win_rate !== null
                            ? b.win_rate.toFixed(2)
                            : "N/A"}
                        </td>
                        <td className="mapmaster-centerAlign">
                          {b.star_player_count ?? 0}
                        </td>
                        <td className="mapmaster-centerAlign">
                          {(b.star_player_rate ?? 0).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>

          <div className="mapmaster-brawler-info-section">
            <div className="mapmaster-sectionHeader">
              <span className="mapmaster-sectionNumber">3.</span>
              <span>Team Compositions (Scroll-{">"})</span>
            </div>
            <div className="mapmaster-tableContainer">
              {sortedTeams.length === 0 ? (
                <p>No team data available</p>
              ) : (
                <>
                  <table className="mapmaster-table">
                    <thead>
                      <tr>
                        <th
                          onClick={() => toggleTeamSort("team")}
                          className={getHeaderClass("team", teamSortColumn)}
                        >
                          Team{" "}
                          {renderSortIcon(
                            "team",
                            teamSortColumn,
                            teamSortDirection
                          )}
                        </th>
                        <th
                          onClick={() => toggleTeamSort("wins")}
                          className={getHeaderClass("wins", teamSortColumn)}
                        >
                          Wins{" "}
                          {renderSortIcon(
                            "wins",
                            teamSortColumn,
                            teamSortDirection
                          )}
                        </th>
                        <th
                          onClick={() => toggleTeamSort("total")}
                          className={getHeaderClass("total", teamSortColumn)}
                        >
                          Total{" "}
                          {renderSortIcon(
                            "total",
                            teamSortColumn,
                            teamSortDirection
                          )}
                        </th>
                        <th
                          onClick={() => toggleTeamSort("wilson_score")}
                          className={getHeaderClass(
                            "wilson_score",
                            teamSortColumn
                          )}
                        >
                          Wilson Score (%){" "}
                          {renderSortIcon(
                            "wilson_score",
                            teamSortColumn,
                            teamSortDirection
                          )}
                          <FaInfoCircle
                            className="info-icon"
                            onClick={() => setShowWilsonInfo(!showWilsonInfo)}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedTeams.map((t, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0
                              ? "mapmaster-evenRow"
                              : "mapmaster-oddRow"
                          }
                        >
                          <td className="mapmaster-teamCell">
                            {t.team.map((brawler, idx) => (
                              <span key={idx} className="mapmaster-teamBrawler">
                                <img
                                  src={BRAWLER_IMAGES[brawler.toLowerCase()]}
                                  alt={brawler}
                                  className="mapmaster-brawlerImageSmall"
                                />
                                {brawler}
                                {idx < t.team.length - 1 ? ", " : ""}
                              </span>
                            ))}
                          </td>
                          <td className="mapmaster-centerAlign">{t.wins}</td>
                          <td className="mapmaster-centerAlign">{t.total}</td>
                          <td className="mapmaster-centerAlign">
                            {t.wilson_score.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {showWilsonInfo && (
                    <div className="wilson-info-box">
                      <strong>About Wilson Score:</strong>
                      <br />
                      The Wilson Score is a statistical measure that provides a
                      conservative estimate of a win rate's confidence interval.
                      A higher Wilson Score suggests a higher confidence in the
                      observed win rate.
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MapMaster;
