// Login.jsx
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import logo from "../assets/logo.png";

function Login({ onLogin }) {
  const [tagInput, setTagInput] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimmedTag = tagInput.trim();

    // Ensure the tag starts with '#'
    let finalTag = trimmedTag.startsWith("#") ? trimmedTag : `#${trimmedTag}`;

    if (finalTag.length < 3) {
      setError("Player Tag must be at least 2 characters long.");
      return;
    }

    setError("");
    setLoading(true);

    try {
      // Use REACT_APP_BACKEND_BASE_URL to match the rest of the app
      const BACKEND_URL =
        process.env.REACT_APP_BACKEND_BASE_URL || "http://localhost:8000";

      console.log(
        "Fetching player data from:",
        `${BACKEND_URL}/api/player/login`
      );

      const response = await fetch(`${BACKEND_URL}/api/player/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ player_tag: finalTag }),
      });

      console.log("Fetched player data");

      if (response.ok) {
        // If successful, parse the data and proceed
        const data = await response.json();
        console.log("Login success:", data);
        onLogin(finalTag);
        navigate("/map-master");
      } else {
        const errorData = await response.json();
        setError(errorData.detail || "An error occurred during login.");
      }
    } catch (err) {
      console.error(err);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Brawl-IQ Logo" className="login-logo" />
      <h1 className="login-title">Brawl360</h1>
      <p className="login-description">
        Boost your strategy and win more with Brawl360!
      </p>
      <form onSubmit={handleSubmit} className="login-form">
        <label htmlFor="playerTag" className="login-label">
          Player Tag:
        </label>
        <input
          id="playerTag"
          type="text"
          ref={inputRef}
          value={tagInput}
          onChange={(e) => setTagInput(e.target.value)}
          className="login-input"
          placeholder="# Player Tag (#89LPPYOU)"
        />
        {error && <p className="login-error">{error}</p>}
        <button type="submit" className="login-button" disabled={loading}>
          {loading ? "Logging In..." : "Log In"}
        </button>
      </form>
    </div>
  );
}

export default Login;
